<template>
  <a-spin :spinning="calculating" tip="Calculating...">
    <div class="mainPage">
      <div class="flex justify-between">
        <p class="pageTitle">Stock base table management</p>
        <div>
          <a-button @click="handleShowModal(null, 'operatingRecord')" type="primary" class="mr8">history</a-button>
          <a-button @click="handleAdd" :disabled="!!selectedRowKeys.length || calculateTaskStatus === 2" type="primary" icon="plus">add</a-button>
          <a-button @click="handleExport(selectedRowKeys)" type="primary" :disabled="!selectedRowKeys.length" icon="export" class="ml16">Export</a-button>
          <a-button v-if="calculateTaskStatus !== 2" @click="handleCalculateOrCancel" type="primary" icon="calculator" class="ml16">Calculate</a-button>
          <a-button v-else @click="handleCancel" type="primary" class="ml16">Cancel</a-button>
          <a-button v-if="calculateTaskStatus === 2"  @click="handleConfirm" type="primary" class="ml16">Confirm</a-button>
          <a-button @click="handleDel(selectedRowKeys, true)" :disabled="!selectedRowKeys.length" type="primary" icon="delete" class="ml16">Delete</a-button>
          <a-upload
            name="file"
            accept=".xlsx"
            :action="importStockBaseInfoUrl"
            :showUploadList="false"
            :multiple="true"
            :headers="headers"
            @change="handleChange"
          >
            <a-button type="primary" :disabled="!!selectedRowKeys.length || calculateTaskStatus === 2" icon="import" class="ml16">Import</a-button>
          </a-upload>
        </div>
      </div>
      <div class="pageWrap">
        <a-table
          :rowKey="record=>record.id"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :data-source="tabelData"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <template slot="LingeringQuantity" slot-scope="estimatedShipmentQuantity, record">
            <div v-if="calculateTaskStatus === 2">
              <span class="mr8">{{estimatedShipmentQuantity}}</span>
              <a-button type="primary" @click="handleShowModal(record.id, 'shipSkuModal')">corresponding tickets</a-button>
            </div>
            <div v-else>-</div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <router-link :to="{path:`/stock/edit/${record.id}`}" class="mr8">edit</router-link>
            <a-button @click="handleDel([record.id])" type="link">delete</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <ship-sku-modal ref="shipSkuModal"></ship-sku-modal>
    <operating-record ref="operatingRecord"></operating-record>
  </a-spin>
</template>

<script>
import ShipSkuModal from './components/ShipSkuModal.vue'
import OperatingRecord from './components/OperatingRecord.vue'

const columns = [
  {
    title: 'Country',
    dataIndex: 'countryCode',
  },
  {
    title: 'ship model ID',
    dataIndex: 'recallModelId',
  },
  {
    title: 'color',
    dataIndex: 'recallColor',
  },
  {
    title: 'ship SKU ID',
    dataIndex: 'recallSkuId',
  },
  {
    title: 'ship SKU number',
    dataIndex: 'recallSku',
  },
  {
    title: 'warehouse ID',
    dataIndex: 'warehouse',
  },
  {
    title: 'Warehouse location',
    dataIndex: 'warehouseAddress',
  },
  {
    title: 'Available stock',
    dataIndex: 'stock',
  },
  {
    title: 'total stock',
    dataIndex: 'totalStock',
  },
  {
    title: 'Estimated Shipment Quantity',
    dataIndex: 'estimatedShipmentQuantity',
    scopedSlots: { customRender: 'LingeringQuantity' },
    width: 260
  },
  {
    title: 'Shipped stock',
    dataIndex: 'usedStock',
  },
  {
    title: 'Shipping Method',
    dataIndex: 'shippingMethod',
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 220
  },
];

export default {
  components: { ShipSkuModal, OperatingRecord },
  data () {
    return {
      loading: false,
      columns,
      tabelData: [],
      selectedRowKeys: [],
      finishCalculate: false,
      calculateTimer: null,
      calculating: false,
      calculateTaskStatus: null,
      pagination: {
        pageSize: 5,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        // pageSizeOptions: [5, 10, 20, 50]
      },
      headers: { "token": localStorage.getItem('token') }
    }
  },
  created () { 
    this.getData()
    this.importStockBaseInfoUrl = PAGECONF.hostApi + '/platform/admin/ticketAdminManage/v1/importStockBaseInfo'
  },
  methods: {
    async getData () {
      try {
        this.loading = true
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/pageStockBaseInfo', {
          currentPage: this.pagination.current,
          pageSize: this.pagination.pageSize
        })
        this.tabelData = res.data.stockBaseInfoList || []
        this.pagination.total = res.data.totalRecord
        this.calculateTaskStatus = res.data.calculateTaskStatus

      } finally {
        this.loading = false
      }
    },
    handleAdd () { 
      this.$router.push('/stock/add')
    },
    handleShowModal (id, refName) { 
      this.$refs[refName].show(id)
    },
    handleDel (ids, isBatch) { 
      if (ids.length === 0) return
      const onOk = async () => { 
        await this.$http.post('/platform/admin/ticketAdminManage/v1/deleteStockBaseInfo', {
          ids
        })
        this.getData()
        if (isBatch) this.selectedRowKeys = []
      }
      this.$confirm({
        title: 'Are you sure to delete?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk,
      });
    },
    async handleExport (ids) {
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/exportStockBaseInfo', {
        ids
      })
      location.href = res.data
    },
    handleConfirm () {
      const onOk = async () => {
        await this.$http.post('/platform/admin/ticketAdminManage/v1/confirmShipping')
        this.pagination.current = 1
        this.getData()
      }
      this.$confirm({
        title: 'Are you sure to ship?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk,
      });
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} file import successfully`)
          this.getData()
        } else {
          this.$error({
            title: 'Error',
            content: info.file.response.errorMsg
          })
        }
      } else if (info.file.status === 'error') {
        this.$message.error('network error')
      }
    },
    async handleCancel () {
      try {
        await this.$http.post('/platform/admin/ticketAdminManage/v1/cancelShipping')
      } finally {
        this.getData()
      }
    },
    async handleCalculateOrCancel () {
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/calculateAndPreShipping')
      this.calculating = true
      this.handleClearInterval()
      this.handlePolling(res.data)
    },
    handlePolling (id) { 
      this.calculateTimer = setInterval(async () => {
        try {
          const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/getCalculateTask', { id })
          if (res.data.status === 2) {
            this.handleClearInterval()
            this.getData()
          } else if (res.data.status === 1) { 
            this.$message.error('Failure to calculate');
          }

          this.calculating = false
        } catch (error) {
          this.handleClearInterval()
          this.calculating = false
        }
      }, 1000)
    },
    handleClearInterval () { 
      clearInterval(this.calculateTimer)
    },
    handleTableChange (pagination) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.getData()
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  destroyed () { 
    this.handleClearInterval()
  }
}
</script>
