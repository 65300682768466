<template>
  <a-modal
      title=""
      v-model="visible"
      :footer="null"
      width="90%"
    >
      <div class="mt24">
        <a-form-model layout="inline">
          <a-form-model-item label="Tickets ID:">
            <a-input v-model="ticketNo" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="getData">Search</a-button>
          </a-form-model-item>
        </a-form-model>

        <a-table
          :rowKey="record=>record.id"
          :columns="columns"
          :data-source="tabelData"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          class="mt16"
        >
          <template slot="operation" slot-scope="text, record">
            <router-link :to="{path:`/ticket/detail/${record.ticketId}`}" target="_blank">Detail</router-link>
          </template>
        </a-table>
      </div>
    </a-modal>
</template>

<script>
import { ticketStatusMapper } from '../../ticket/var'

const columns = [
  {
    title: 'Tickets ID',
    dataIndex: 'ticketNo',
  },
  {
    title: 'Country',
    dataIndex: 'countryCode',
  },
  {
    title: 'model ID',
    dataIndex: 'modelId',
  },
  {
    title: 'SKU ID',
    dataIndex: 'skuId',
  },
  {
    title: 'Shipping model ID',
    dataIndex: 'recallModelId',
  },
  {
    title: 'Shipping SKU',
    dataIndex: 'recallSkuId',
  },
  {
    title: 'Check Ticket ID',
    dataIndex: 'ticketId',
  },
  {
    title: 'Last Operator',
    dataIndex: 'lastOperator',
  },
  {
    title: 'Ticket state',
    dataIndex: 'status',
    customRender: (status) => {
      return ticketStatusMapper[status]
    }
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  },
];

export default {
  data () { 
    return {
      visible: false,
      ticketNo: null,
      loading: false,
      tabelData: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        // pageSizeOptions: [5, 10, 20, 50]
      },
      recallStockBaseId: null
    }
  },
  created () { 
    this.columns = columns
  },
  methods: {
    show (recallStockBaseId) { 
      this.recallStockBaseId = recallStockBaseId
      this.visible = true
      this.getData()
    },
    async getData () {
      try {
        this.loading = true
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/pageCorrespondingTicket', {
          currentPage: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ticketNo: this.ticketNo,
          recallStockBaseId: this.recallStockBaseId
        })
        this.tabelData = res.data.shippingInfoList || []
        this.pagination.total = res.data.totalRecord
      } finally {
        this.loading = false
      }
    },
    handleTableChange (pagination) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.getData()
    }
  }
}
</script>
