<template>
  <a-modal
      title="操作记录"
      v-model="visible"
      :footer="null"
      :width="1100"
    >
      <a-spin :spinning="loading">
        <div class="flex mt16" v-for="(item, index) in tabelData" :key="index">
          <div>{{timeFormatUSA(item.operateTime, 'YYYY/MM/DD HH:mm:ss', 'America/New_York')}}(NYC)</div>
          <div class="ml16" v-html="oprText(item)"></div>
        </div>
        <a-pagination 
          :total="pagination.total" 
          @change="handleChangePageNum" 
          @showSizeChange="handleChangeSize" 
          show-size-changer 
          show-quick-jumper class="mt16"/>
        </a-spin>
    </a-modal>
</template>

<script>
import { timeFormatUSA } from '@/utils/utils.js'
export default {
  data () { 
    return {
      visible: false,
      operateLogList: [],
      loading: false,
      tabelData: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        // pageSizeOptions: [5, 10, 20, 50]
      },
    }
  },
  methods: {
    timeFormatUSA,
    show () { 
      this.visible = true
      this.getData()
    },
    async getData () {
      try {
        this.loading = true
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/pageStockOperateLog', {
          currentPage: this.pagination.current,
          pageSize: this.pagination.pageSize,
        })
        this.tabelData = res.data.operateLogList || []
        this.pagination.total = res.data.totalRecord
      } finally {
        this.loading = false
      }
    },
    handleChangePageNum (pageNum) { 
      this.pagination.current = pageNum
      this.getData()
    },
    handleChangeSize (current, size) {
      this.pagination.pageSize = size
      this.getData()
    },
    oprText (item) {
      const comText = `发货国家为${item.countryCode},发货SKU ID为<span class="color-blue">${item.recallSkuId}</span>,发货SKU number为<span class="color-blue">${item.recallSku}</span>, 仓库ID为<span class="color-blue">${item.warehouse}</span>`
      const actionText = {
        delete: `<span class="color-blue">${item.operator}</span>删除了${item.recallModelId},${comText}`,
        import: `<span class="color-blue">${item.operator}</span>导入${item.recallModelId}库存${item.stock},${comText}`,
        shipp: `<span class="color-blue">${item.operator}</span>发货了${item.recallModelId},${comText}`
      }
      return actionText[item.operateType]
    }
  }
}
</script>

<style lang="less">
.color-blue {
  color: #02A7F0
}
</style>
